.lineMoveUp {
  stroke-dasharray: 400;
  stroke-dashoffset: 0;
  stroke: aqua;
  animation: animate-up 5s linear forwards;
  animation-iteration-count: infinite;
}

.lineMoveCenter {
  stroke-dasharray: 300;
  stroke-dashoffset: 0;
  stroke: aqua;
  animation: animate-center 4s linear forwards;
  animation-iteration-count: infinite;
  animation-delay: 1.5s;
}

.lineMoveDown {
  stroke-dasharray: 300;
  stroke-dashoffset: 0;
  stroke: aqua;
  animation: animate-down 5s linear forwards;
  animation-iteration-count: infinite;
  animation-delay: 3s;
}

@keyframes animate-up {
  0% {
    stroke-dashoffset: 1600;
  }
  40% {
    stroke-dashoffset: 800;
  }
  80% {
    stroke-dashoffset: 400;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-center {
  0% {
    stroke-dashoffset: 1200;
  }
  40% {
    stroke-dashoffset: 600;
  }
  80% {
    stroke-dashoffset: 300;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes animate-down {
  0% {
    stroke-dashoffset: 1200;
  }
  40% {
    stroke-dashoffset: 600;
  }
  80% {
    stroke-dashoffset: 300;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

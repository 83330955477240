.PhoneInput {
  width: 44.5%;
}
.PhoneInputInput {
  padding-block: 0.75rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: black;
  font-size: 16px;
  outline: none;
}

.PhoneInputInput::placeholder {
  padding-inline: 10px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: rgb(168, 168, 168);
}

.PhoneInputCountry {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: white;
  color: black;
  padding-inline: 13px;
  margin: 0;
}

@media only screen and (max-width: 639px) {
  .PhoneInput {
    width: 320px;
    font-size: 12px;
  }
  .PhoneInputCountry {
    padding-inline: 5px;
  }
  .PhoneInputInput::placeholder {
    font-size: 12px;
    padding-inline: 0;
  }
}

.checkbox-wrapper-4 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-4 .toggle1 {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .toggle1:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #b0cdff;
  border-radius: 8px;
  transition: background 0.2s ease;
}
.checkbox-wrapper-4 .toggle1 span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #1862e3;
  border-radius: 10px;
  box-shadow: 0 3px 8px #b0cdff;
  transition: all 0.2s ease;
}
.checkbox-wrapper-4 .toggle1 span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: #b0cdff;
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

.checkbox-wrapper-4 #cbx-4:checked + .toggle1:before {
  background: #b0cdff;
}
.checkbox-wrapper-4 #cbx-4:checked + .toggle1 span {
  background: #1862e3;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px #1862e3;
}
.checkbox-wrapper-4 #cbx-4:checked + .toggle1 span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

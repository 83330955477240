@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

.blueButton:hover {
  transition: background ease-in-out 0.2s;
  background: #44bafe;
}

.blueButton:active {
  transition: background ease-in-out 0.1s;
  background: #0077ff;
}

.whiteButton:hover {
  transition: background ease-in-out 0.2s;
  background: #44bafe;
  color: white;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}

.whiteButton:active {
  transition: background ease-in-out 0.2s;
  background: white;
  color: #07a4fc;
}

.Image-Header {
  background-image: url("./assets/backHeader.webp");
  background-repeat: no-repeat;
  object-position: center;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  /* animation: lolo 2s; */
}

/* @keyframes lolo {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 30%;
  }
  80% {
    opacity: 60%;
  }
  100% {
    opacity: 100%;
  }
} */

.bg-Customer {
  background-image: url("./assets/BGCustomer.webp");
  object-fit: cover;
  background-repeat: no-repeat;
}

.logo > img {
  filter: grayscale(100%);
  opacity: 45%;
}

.Circle {
  background: url("./assets/CircleGroup.webp");
  background-repeat: no-repeat;
  background-position: center;
  object-position: center;
  background-color: #07a4fc;
}

.animation-Play {
  border: 30px solid rgb(255, 255, 255);
  animation: animation-offset forwards ease-in-out 5s infinite;
}

.CustomertBg {
  background: url("./assets/commentBg.webp");
  background-repeat: no-repeat;
  background-position: center;
  object-position: center;
  background-color: #07a4fc;
}

@keyframes animation-offset {
  0% {
    border-color: rgb(255, 255, 255);
  }
  30% {
    border-color: rgb(202, 202, 202);
  }
  68% {
    border-color: rgb(255, 255, 255);
  }
  85% {
    border-color: rgb(202, 202, 202);
  }
  100% {
    border-color: rgb(255, 255, 255);
  }
}

@layer components {
  .button {
    @apply bg-bluePirelly px-6 rounded-xl py-3 text-white;
  }
}

.shadowDef {
  box-shadow: 0px 1px 16px -1px rgba(0, 0, 0, 0.08);
}

.shadowCompany {
  box-shadow: 0px 4px 40px 0px rgba(43, 89, 255, 0.08);
}

.shadowLogo {
  box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.07);
}

.shadowComment {
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
}

.shadowAi {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.shadowQuest {
  box-shadow: 0px 4px 40px 0px rgba(43, 89, 255, 0.08);
}

.bgCards {
  background: url("../../assets/bgCards.webp");
  background-repeat: no-repeat;
  object-fit: cover;
  background-position-x: center;
  width: -webkit-fill-available;
}

.swiper {
  height: 350px;
}

.swiper-slide {
  align-items: baseline;
}

.swiper-pagination {
  bottom: 0;
}

.swiper-pagination-bullet {
  background-color: white;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: white;
  width: 15px !important;
  padding: 1.5em;
  border-radius: 100%;
  height: 15px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1em;
}

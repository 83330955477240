.animateClass1 {
  position: relative;
  top: 0;
  opacity: 1;
  background-color: rgb(125, 125, 125);
  animation: 1s buyn ease-in-out infinite;
}
.animateClass2 {
  position: relative;
  top: 0;
  opacity: 1;
  animation-delay: 5s;
  background-color: rgb(125, 125, 125);
  animation: 1s buyn 1s ease-in-out infinite;
}
.animateClass3 {
  position: relative;
  top: 0;
  opacity: 1;
  background-color: rgb(125, 125, 125);
  animation: 1s buyn 2s ease-in-out infinite;
  animation-delay: 5s;
}
@keyframes buyn {
  0% {
    top: -3px;
    opacity: 0.5;
    background-color: rgb(183, 182, 182);
  }
  50% {
    top: 0px;
    opacity: 1;
    background-color: rgb(125, 125, 125);
  }
  100% {
    top: -3px;
    opacity: 0.5;
    background-color: rgb(183, 182, 182);
  }
}

.bg-gradiant-sellOnline {
  background: rgb(25, 87, 255);
  background: linear-gradient(
    180deg,
    rgba(31, 162, 253, 1) 0%,
    rgba(31, 162, 253, 1) 100%
  );
}

.CircleBg {
  background: url("../../assets/CircleGroup.webp");
  object-fit: fill;
  background-repeat: no-repeat;
  background-position: center;
  object-position: center;
}
